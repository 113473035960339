import React from 'react'
import LinkWrap from 'components/LinkWrap'
import { trFunction } from 'utils/functions'

const OfficeGrid = ({ offices, links, data }) => {
  const locale = data.site.siteMetadata.locale
  const translations = data.translations
  const tr = (code) => trFunction(code, translations, locale)

  return (
    <div className="office-grid">
      <div className="inner">
        <div className="grid">
          {offices.map((office, i) => (
            <Office key={i} office={office.node} />
          ))}
        </div>
        {links && (
          <div className="links">
            <LinkWrap to="/catalog" id="download-cat">
              <span className="icon-download" />
              {tr('VIEW_CATALOGS')}
            </LinkWrap>
            <LinkWrap to="/collections/distribution">
              {tr('SEE_DISTRIBUTORS')}
            </LinkWrap>
          </div>
        )}
      </div>
    </div>
  )
}

export default OfficeGrid

const Office = ({ office }) => {
  return (
    <div className="office">
      <h2>{office.title}</h2>
      <div className="address">
        <p>
          {office.address1 && (
            <span>
              {office.address1}
              <br />
            </span>
          )}
          {office.address2 && (
            <span>
              {office.address2}
              <br />
            </span>
          )}
          {office.phone && (
            <span>
              {office.phone}
              <br />
            </span>
          )}
          {office.email && (
            <span>
              <a href={`mailto:${office.email}`}>{office.email}</a>
              <br />
            </span>
          )}
        </p>
      </div>
    </div>
  )
}
