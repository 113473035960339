import React, { Component } from 'react'
import ContentBlock from 'components/contentBlock'
import Layout from 'components/layout'
import Title from 'components/title'
import OfficeGrid from 'components/officeGrid'
import { trFunction } from 'utils/functions'
import { graphql } from 'gatsby'

const Dealer = ({ dealer, accordionToggle, openId }) => {
  const openIdClass = openId === dealer.id ? 'open' : ''
  return (
    <div className={`item accordion-holder ${openIdClass}`}>
      <div
        className="trigger accordion-trigger has-arrow"
        onClick={accordionToggle}
        data-id={dealer.id}
      >
        {dealer.title}
        <div className="arrow-right-ac" />
      </div>
      <div className="details accordion-hide">
        {dealer.content && (
          <div
            className="inner"
            dangerouslySetInnerHTML={{
              __html: dealer.content.childMarkdownRemark.html,
            }}
          />
        )}
      </div>
    </div>
  )
}

class Dealers extends Component {
  constructor(props) {
    super(props)
    this.state = {
      openId: '',
    }
    this.accordionToggle = this.accordionToggle.bind(this)
  }

  accordionToggle(e) {
    const getId = e.currentTarget.getAttribute('data-id')
    this.setState((prevState) => {
      if (prevState.openId === getId) {
        return { openId: '' }
      } else {
        return { openId: getId }
      }
    })
  }

  render() {
    const data = this.props.data
    const locale = this.props.data.site.siteMetadata.locale
    const translations = this.props.data.translations
    const tr = (code) => trFunction(code, translations, locale)
    let page = this.props.data.thisPage
    const dealers = this.props.data.allDealers.edges
    const offices = this.props.data.allOffices.edges

    let itemChina = {}
    let itemUS = {}
    let itemChinaTag
    let itemUSTag
    for (let i = 0; i < dealers.length; i++) {
      if (dealers[i].node.slug === 'china') itemChinaTag = i
      if (dealers[i].node.slug === 'united-states') itemUSTag = i
    }
    itemChina = dealers.splice(itemChinaTag, 1)
    itemUS = dealers.splice(itemUSTag - 1, 1)
    dealers.unshift(itemUS[0])
    dealers.unshift(itemChina[0])

    page.contentBlocks = page.contentBlocks.slice(0, 1) // ***post-launch
    const afterTitle = `${tr('OFFICE')} / ${tr('SHOWROOM')}`

    return (
      <Layout>
        <div id="content" className="page-dealers">
          <Title
            title={page.title}
            // subtitle={tr('COLLECTIONS')}
            // subtitleLink="/collections/"
          />
          {page.contentBlocks.map((block, i) => (
            <ContentBlock key={i} order={i} block={block} page={page} />
          ))}
          <section className="module _trigger-in-view fade text_content">
            <div className="wrap">
              <div className="wrap-text-large centered strip-p single-commons">
                <div className="only_text">
                  <p style={{ textAlign: 'center' }}>
                    Our worldwide network of showrooms, dealers, and
                    distributors.
                  </p>
                  <h3
                    className="small-caps title-accordeon"
                    style={{ marginTop: '60px', color: 'rgb(74,74,74)' }}
                  >
                    {afterTitle}
                  </h3>
                </div>
              </div>
            </div>
          </section>
          <OfficeGrid offices={offices} links={false} data={data} />
          <section className="page-content wrap">
            <div className="dealers-list">
              <div className="layout-full">
                <div className="col">
                  <div className="text">
                    <h3 className="small-caps title-accordeon">
                      {tr('DEALERS')}
                    </h3>
                    <p>
                      <a className="button" href="/contact">
                        {tr('BECOME_A_DEALER')}
                      </a>
                    </p>
                  </div>
                  <div className="module_accordion module-dealers">
                    <div className="items">
                      {dealers.map((dealer, i) => (
                        <Dealer
                          key={i}
                          dealer={dealer.node}
                          accordionToggle={this.accordionToggle}
                          openId={this.state.openId}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </Layout>
    )
  }
}
export default Dealers

export const dealersPageQuery = graphql`
  query {
    site {
      siteMetadata {
        locale
      }
    }
    translations: allContentfulStringTranslations {
      edges {
        node {
          code
          en
          zh
        }
      }
    }
    thisPage: contentfulPages(slug: { eq: "distribution" }) {
      title
      contentBlocks {
        id
        slug
        template
        label
        title
        subtitle
        textPosition
        content {
          id
          childMarkdownRemark {
            html
          }
        }
        imagesFull {
          ...GatsbyImage
        }
        imagesFullMobile {
          ...GatsbyImage
        }
        imagesFullLink
        buttonLink
        imageSingle {
          ...GatsbyImage
        }
        buttonLink
        video {
          wistiaCode
        }
        slides {
          images {
            ...GatsbyImage
          }
          caption
        }
      }
    }
    allDealers: allContentfulDealers(sort: { fields: [title] }) {
      edges {
        node {
          id
          slug
          title
          content {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
    allOffices: allContentfulOffices(sort: { fields: [order] }) {
      edges {
        node {
          id
          title
          address1
          address2
          phone
          email
        }
      }
    }
  }
`
